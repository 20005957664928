<template>
	<div class="w-full h-full dF fC aS" style="
            display: inline-block;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        ">
		<LeftNavBar class="f1" :items="sidebarItems" @opened="selectSideBar" :title="selectedAppInitial"
			:selectedValue="sidebarSelection">
		</LeftNavBar>
	</div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
	components: { LeftNavBar },
	name: "menu-left",
	data() {
		return {
			openID: "",
		};
	},
	computed: {
		selectedAppInitial() {
			return (
				(this.$store.state.theApp &&
					this.$store.state.theApp.media &&
					this.$store.state.theApp.media.initial) ||
				"WM"
			);
		},
		isAgent() {
			return this.$userType === 'agent'
		},
		sidebarItems() {
			if (this.isAgent || this.$p === 10) {
				return [
					{
						label: "Worksheets",
						value: "Worksheets",
						iconUrl: require("@/assets/worksheet.svg"),
						options: [],
						children: [],
					},
					{
						label: "Archived",
						value: "Archived",
						iconUrl: require("@/assets/archived.svg"),
						options: [],
						children: [],
					},
				]
			}

			return [
				{
					label: "Worksheets",
					value: "Worksheets",
					iconUrl: require("@/assets/worksheet.svg"),
					options: [],
					children: [],
				},
				{
					label: "Archived",
					value: "Archived",
					iconUrl: require("@/assets/archived.svg"),
					options: [],
					children: [],
				},
				{
					divider: true,
				},
				{
					label: "Settings",
					value: "Settings",
					iconUrl: require("@/assets/cog-solid.svg"),
					options: [],
					children: [],
				},
			]
		},
		sidebarSelection() {
			return this.$store.state.sidebarSelection;
		},
	},
	methods: {
		selectSideBar(data) {
			if (this.sidebarSelection != data.value) {
				this.$store.commit("SIDEBAR_SELECTION", data.value);
			}
			if (data.value == "Settings" && this.$route.path !== "/settings") {
				return this.$router.push("/settings");
			} else if (data.value == "Archived" && this.$route.path !== "/archived") {
				return this.$router.push('/archived')
			} else if (this.$route.path !== "/") {
				this.$router.push('/')
			}
		},
	},
};
</script>
