<template>
	<div>
		<div :class="$style.subbar" v-if="instance" style="background-color: #f7f5f9">
			<div class="d-sm-flex aC mb-0">
				<div :class="$style.breadcrumbs" style="align-items: center; display: block !important">
					<a-breadcrumb>
						<a-breadcrumb-item>{{
							instance.name
						}}</a-breadcrumb-item>
						<a-breadcrumb-item>{{
							selectedAppInitial
						}}</a-breadcrumb-item>
						<a-breadcrumb-item style="color: var(--orange)">{{
							sidebarSelection
						}}</a-breadcrumb-item>
					</a-breadcrumb>
				</div>

				<div :class="$style.amount" v-if="$route.path == '/'" class="ml-auto d-sm-flex mt-3 mt-sm-0">
					<div class="d-none d-sm-block">
						<a-auto-complete size="large" v-model="tempSearch" style="width: 250px"
							placeholder="Search for a Worksheet..." :dropdown-style="{ width: '250px' }"
							@search="handleSearch" class="searchBar" allowClear>
							<a-input>
								<a-icon slot="prefix" type="search" />
							</a-input>
						</a-auto-complete>
					</div>

					<template v-if="$store.getters.canAddWorksheet">
						<div class="mx-4 d-none d-xl-block dash-divider" />
						<a-button @click="addNew" type="primary" icon="plus" size="large">
							ADD A {{ sidebarSelection.toUpperCase().slice(0, -1) }}
						</a-button>
					</template>
				</div>
			</div>
		</div>
		<a-modal :visible="worksheetFormModal" @cancel="worksheetFormModal = false;" :centered="true"
			title="Worksheet Form" okText="ADD NEW WORKSHEET" @ok="createNew">
			<a-form-model ref="worksheetForm" :model="worksheetForm" class="profile">
				<a-form-model-item label="Select Worksheet Form" required
					:rules="{ required: true, message: 'Please select a worksheet form' }" prop="id">
					<a-select v-model="worksheetForm.id" size="large" option-label-prop="label">
						<a-select-option v-for="worksheetForm in worksheetForms" :key="worksheetForm.id"
							:value="worksheetForm.id" :label="worksheetForm.name">
							{{ worksheetForm.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>


<script>
import { getAppIcon } from "bh-mod";

export default {
	data() {
		return {
			worksheetFormModal: false,
			worksheetForm: {
				id: null
			},
			tempSearch: "",
		};
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.tempSearch = this.$store.state.globalSearch;
			},
		},
	},
	computed: {
		selectedAppInitial() {
			return this.$store.state.theApp?.media?.initial || "WM";
		},
		sidebarSelection() {
			return this.$store.state.sidebarSelection;
		},
		instance() {
			return this.$store.state.instance;
		},
		theApp() {
			return this.$store.state.theApp || {};
		},
		worksheetForms() {
			return this.$store.state.appData.worksheetForms;
		},
	},
	methods: {
		handleSearch(input) {
			let search = input && input.trim() || ''
			this.$store.commit("UPDATE_GLOBAL_SEARCH", search.toLowerCase())
		},
		addNew() {
			if (!this.worksheetForms.length) {
				return this.$message.error('Worksheet form is not set up yet. Please contact owner.');
			}
			this.worksheetForm.id = this.worksheetForms[0].id;
			this.worksheetFormModal = true;
		},
		createNew() {
			if (!this.$store.getters.canAddWorksheet) return;

			this.$refs.worksheetForm.validate(async (valid) => {
				if (valid) {
					const selectedWorksheetForm = this.worksheetForms.find(w => w.id === this.worksheetForm.id)
					if (selectedWorksheetForm) {
						this.$store.commit('SET_WORKSHEET', null)
						this.$store.commit('SET_WORKSHEET_FORM', selectedWorksheetForm)
						this.worksheetForm.id = null;
						this.worksheetFormModal = false;
						if (this.$route.path !== "/create") {
							this.$router.push('/create')
						}
					}
				}
			})
		},
		getAppIcon,
	},
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.ant-form-horizontal.search-horizontal {
	.ant-form-item-control-wrapper {
		flex: 1;
	}

	.ant-form-item {
		display: flex;
	}

	.ant-form-item-label {
		min-width: 100px;
	}
}

.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
	margin-left: 32px;
}
</style>
