import Vue from "vue";
import Router from "vue-router";
import Splash from "@/layouts/Splash";
import AuthLayout from 'bh-mod/layouts/Auth'
import store from "@/store";
import { VueAppMiddleware } from "bh-mod";
import AppLayout from "bh-mod/layouts/App";

Vue.use(Router);
const router = new Router({
	base: process.env.BASE_URL,
	mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			redirect: "/v3",
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true },
			children: [
				// Dashboards
				{
					path: "/",
					meta: {
						title: "Worksheet Management",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/app"),
				},
				{
					path: "/archived",
					meta: {
						title: "Archived Worksheet",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/worksheet/archived"),
				},
				{
					path: "/create",
					meta: {
						title: "Create Worksheet",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/worksheet"),
				},
				{
					path: "/edit/:id",
					meta: {
						title: "Edit Worksheet",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("./views/worksheet"),
				},
				{
					path: '/settings',
					meta: {
						title: 'Settings',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/settings'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true,
					},
					component: () => import("bh-mod/components/layout/TopBar/Actions/notifications.vue"),
				},
			],
		},
		{
			path: "/setup",
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [
				{
					path: "/setup",
					meta: {
						title: "Setup Worksheet Management",
						splashScreen: true,
					},
					component: () => import("./views/setup"),
				},
			],
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
});

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'worksheets')
		error = response.parsed
		hiveRequested = true;
	}

	if (to.path.startsWith("/settings") && Vue.prototype.$userType === 'agent') {
		return next("/");
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}

		const seenIntro = store.state?.appData?.allSettings?.userApp?.options?.seenIntro ?? false;
		const getStarted = store.state?.appData?.getStarted ?? false;

		if (splashScreen) {
			if (seenIntro) {
				return finalRouter(to, next);
			}
		}
		if (dataRequired) {
			if (!getStarted && !seenIntro) {
				return next('/setup');
			}
		}
	}
	finalRouter(to, next);
});

function finalRouter(to, next) {
	if (to.path.startsWith("/setup")) {
		return next();
	}

	if (to.path.startsWith("/settings") && (Vue.prototype.$userType === 'agent' || Vue.prototype.$p === 10)) {
		return next("/");
	}
	return next();
}

export default router;
