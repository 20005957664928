import { render, staticRenderFns } from "./FileHolder.vue?vue&type=template&id=fa574af8&scoped=true"
import script from "./FileHolder.vue?vue&type=script&lang=js"
export * from "./FileHolder.vue?vue&type=script&lang=js"
import style0 from "./FileHolder.vue?vue&type=style&index=0&id=fa574af8&prod&lang=scss&module=true"
import style1 from "./FileHolder.vue?vue&type=style&index=1&id=fa574af8&prod&lang=scss"
import style2 from "./FileHolder.vue?vue&type=style&index=2&id=fa574af8&prod&lang=scss&scoped=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../worksheets/node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "fa574af8",
  null
  
)

export default component.exports